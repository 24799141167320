export default [
  {
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2023, 11, 14, 18, 30, 0),
    'end': new Date(2023, 11, 14, 19, 30, 0)
  },
  {
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2023, 11, 21, 18, 30, 0),
    'end': new Date(2023, 11, 21, 19, 30, 0)
  },
  {
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2024, 0, 11, 18, 30, 0),
    'end': new Date(2024, 0, 11, 19, 30, 0)
  },
  {
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2024, 0, 18, 18, 30, 0),
    'end': new Date(2024, 0, 18, 19, 30, 0)
  },
  {
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2024, 0, 25, 18, 30, 0),
    'end': new Date(2024, 0, 25, 19, 30, 0)
  },
  {
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2024, 1, 1, 18, 30, 0),
    'end': new Date(2024, 1, 1, 19, 30, 0)
  },{
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2024, 1, 8, 18, 30, 0),
    'end': new Date(2024, 1, 8, 19, 30, 0)
  },{
    'title': 'Séance 1h salle Atout - passée',
    'start': new Date(2024, 1, 15, 18, 30, 0),
    'end': new Date(2024, 1, 15, 19, 30, 0)
  },{
    'title': 'Séance 1h salle Atout - 3 places restantes',
    'start': new Date(2024, 1, 22, 18, 30, 0),
    'end': new Date(2024, 1, 22, 19, 30, 0)
  },{
    'title': 'Séance 1h salle Atout - 3 places restantes',
    'start': new Date(2024, 2, 14, 18, 30, 0),
    'end': new Date(2024, 2, 14, 19, 30, 0)
  },{
    'title': 'Séance 1h salle Atout - 3 places restantes',
    'start': new Date(2024, 2, 21, 18, 30, 0),
    'end': new Date(2024, 2, 21, 19, 30, 0)
  },{
    'title': 'Séance 1h salle Atout - 3 places restantes',
    'start': new Date(2024, 2, 28, 18, 30, 0),
    'end': new Date(2024, 2, 28, 19, 30, 0)
  }
]