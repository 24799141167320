import './App.css';
import React, { useState, useEffect } from 'react';
import { useScroll, animated, useSpring } from '@react-spring/web';
import classes from './classes';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import './assets/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr'); //set to french

/*
Idees pour menu : 
https://codesandbox.io/s/6zchkl ++++++
https://codepen.io/mikel301292/pen/dMYRYZ
https://codepen.io/phantomesse/pen/kPKGYy
https://codepen.io/CarlRosell/pen/nRydBq
https://codepen.io/rachsmith/pen/AMVQLw
*/
/*
Pour contact : 
https://codesandbox.io/s/pf74x
https://www.joshwcomeau.com/react/boop/
*/


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function getDocumentDimensions() {
  const { offsetWidth: width, offsetHeight: height } = window.document.body;
  return {
    width,
    height
  };
}
// USEFUL
function strUcFirst(a) {
  return a.charAt(0).toUpperCase() + a.slice(1);
}
//TODO check if getContactSize and getContactPlace updated for CSS everytime window is resized

function App() {
  // Hamburger menu
  // function openMenu() {
  //   let menu = document.querySelector(".menu");
  //   let h_menu = document.querySelector(".hamburger-menu");
  //   if (!menu.classList.contains("showMenu")) {
  //     menu.classList.add("showMenu");
  //     h_menu.classList.add("open-menu");
  //   }
  // };
  function closeMenu() {
    let menu = document.querySelector(".menu");
    let h_menu = document.querySelector(".hamburger-menu");
    if (menu.classList.contains("showMenu")) {
      menu.classList.remove("showMenu");
      h_menu.classList.remove("open-menu");
    }
  };
  function toggleMenu() {
    let menu = document.querySelector(".menu");
    let h_menu = document.querySelector(".hamburger-menu");
    if (menu.classList.contains("showMenu")) {
      menu.classList.remove("showMenu");
      h_menu.classList.remove("open-menu");
    } else {
      menu.classList.add("showMenu");
      h_menu.classList.add("open-menu");
    }
  };

  // Illustration handle scroll
  const [scrollVal, setScrollVal] = useState(0);
  const [scrollVal_logo, setScrollVal_logo] = useState(0);
  const [scrollVal_wlogo, setScrollVal_wlogo] = useState(0);
  const { scrollYProgress } = useScroll({
    onChange: ({ value: { scrollYProgress } }) => {
      if ((scrollYProgress * getDocumentDimensions().height)/(2.6*getWindowDimensions().height) <= 1) {
        setScrollVal((scrollYProgress * getDocumentDimensions().height)/(2.6*getWindowDimensions().height));
        setScrollVal_wlogo(0);
      } else {
        setScrollVal(1);
        setScrollVal_wlogo(((scrollYProgress - (2.6*getWindowDimensions().height)/getDocumentDimensions().height) * getDocumentDimensions().height)/(0.6*getWindowDimensions().height));
      }
      if ((scrollYProgress * getDocumentDimensions().height)/(3.4*getWindowDimensions().height) > 1) {
        setScrollVal_logo(1);
      } else {
        setScrollVal_logo(0);
      }
    }
  });

  // Handle scrolling on parts
  useEffect(() => {
    const handleScroll = () => {
        const sections = document.querySelectorAll('section[id]');
        const scrollPosition = window.scrollY;
  
        sections.forEach(section => {
          const sectionTop = section.offsetTop;
          console.log(sectionTop , section.offsetHeight , "" , section , scrollPosition) ;
          const sectionBottom = sectionTop + section.offsetHeight;
  
          if (scrollPosition >= sectionTop  && scrollPosition < sectionBottom) {
            window.history.replaceState(null, null, `#${section.id}`);
          }
        });
      };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Calendar
  const localizer = momentLocalizer(moment);

  return (
    <div className='whole-page'>
      <div className='message-device'>
        <p>Pour avoir accès à toute la page, veuillez la visionner en mode paysage ou sur un écran plus grand, merci !</p>
      </div>
      <div className='menu-container' onMouseLeave={closeMenu}>
        <div className='hamburger-menu' onClick={toggleMenu}>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
        <ul className='menu'>
          <li><a className='menuItem' href='#accueil'>Accueil</a></li>
          <li><a className='menuItem' href='#yoga'>Le yoga</a></li>
          <li><a className='menuItem' href='#prof'>La prof</a></li>
          <li><a className='menuItem' href='#calendrier'>Le calendrier</a></li>
          <li><a className='menuItem' href='#prix'>Les prix</a></li>
          <li><a className='menuItem' href='#contact'>Me contacter</a></li>
        </ul>
      </div>
      <animated.div className='logo' style={{opacity: `${scrollVal_logo}`}}>
        <a href='#accueil'>
          <img 
            src='/img/logo_dark.png'
            alt='logo'
            width='100%'
          />
        </a>
      </animated.div>
      <div className='illustration-container' id='accueil'>
        <div className='sticky-div'>
          <div className='illustrations'>
            <animated.div className='item moon' style={{transform: `translate(${- scrollVal * 3}vw, ${scrollVal * 1}vw)`, opacity: `${1-scrollVal_wlogo}`}}>
              <img
                src='/img/moon.png'
                alt='moon illustration'
                width="100%"
              />
            </animated.div>
            <div className='item floor'>
              <img
                src='/img/floor.png'
                alt='floor illustration'
                width="100%"
              />
            </div>
            <animated.div className='item mountain1' style={{transform: `translate(${-scrollVal * 12.97}vw, 0%)`}}>
              <img
                src='/img/mountain1.png'
                alt='mountain1 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain2' style={{transform: `translate(${scrollVal * 8.57}vw, 0%)`}}>
              <img
                src='/img/mountain2.png'
                alt='mountain2 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain7' style={{transform: `translate(${scrollVal * 10.54}vw, 0%)`}}>
              <img
                src='/img/mountain7.png'
                alt='mountain7 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain3' style={{transform: `translate(${scrollVal * 15.68}vw, 0%)`}}>
              <img
                src='/img/mountain3.png'
                alt='mountain3 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain4' style={{transform: `translate(${-scrollVal * 17.33}vw, 0%)`}}>
              <img
                src='/img/mountain4.png'
                alt='mountain4 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item tree' style={{transform: `translate(${scrollVal * 7.41}vw, 0%)`}}>
              <img
                src='/img/tree.png'
                alt='tree illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain10' style={{transform: `translate(${-scrollVal * 21.65}vw, 0%)`}}>
              <img
                src='/img/mountain10.png'
                alt='mountain10 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain6' style={{transform: `translate(${scrollVal * 12.87}vw, 0%)`}}>
              <img
                src='/img/mountain6.png'
                alt='mountain6 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain5' style={{transform: `translate(${-scrollVal * 26}vw, 0%)`}}>
              <img
                src='/img/mountain5.png'
                alt='mountain5 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain9' style={{transform: `translate(${scrollVal * 34.77}vw, 0%)`}}>
              <img
                src='/img/mountain9.png'
                alt='mountain9 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item mountain8' style={{transform: `translate(${-scrollVal * 38.79}vw, 0%)`}}>
              <img
                src='/img/mountain8.png'
                alt='mountain8 illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item sit' style={{transform: `scale(${scrollVal * 0.28 + 1})`}}>
              <img
                src='/img/sit.png'
                alt='sit illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item blob' style={{transform: `scale(${scrollVal * 0.28 + 1}) translate(0%, ${-scrollVal * 1.5}vw)`}}>
              <img
                src='/img/logo_dark_paint.png'
                alt='blob illustration'
                width="100%"
              />
            </animated.div>
            <animated.div className='item whole-logo' style={{opacity: `${scrollVal_wlogo}`}}>
              <img
                src='/img/whole_logo_no_blob.png'
                alt='whole logo'
                width="100%"
              />
            </animated.div>
          </div>
        </div>
      </div>
      <div className='wrapper'>   
        <div className='yoga-container' id='yoga'>
          <div className='yoga-grid'>
            <h1 className='yoga-title'>Le yoga</h1>
            <div className='yoga-txt1'>Quelle image avez-vous du yoga ? Nous entendons parfois que c’est un sport, qu’il est question de performance physique, de souplesse et d'équilibre. Le yoga est avant tout une pratique permettant de calmer le mental, d’en retrouver le contrôle. Et pour atteindre cet état de plénitude, nous pouvons utiliser le corps, grâce à des postures (asana), grâce à des respirations (pranayama). Le but n’est pas de faire quelque chose de joli mais quelque chose qui nous fait du bien, qui vient chercher en nous des ressources souvent insoupçonnées.</div>
            <div className='yoga-img1'>
              <img 
                src='/img/yoga_img1.png'
                alt='yoga_img1'
                width='85%'
              />
            </div>
            <div className='yoga-txt2'>L'écoute de soi, de son corps, de ses limites est primordial dans le yoga. Cette écoute, mise à mal dans notre vie quotidienne, est développée à chaque séance, dans les postures mais aussi dans les temps de méditation, de relaxation. Et c’est avec cette écoute que nous pouvons développer notre souplesse, de l’esprit et du corps, gagner en musculature profonde ainsi qu’en endurance. Nous travaillons avec le corps et non contre lui.</div>
            <div className='yoga-img2'>
              <img 
                src='/img/yoga_img2.png'
                alt='yoga_img2'
                width='85%'
              />
            </div>
            <div className='yoga-txt3'>Le Hatha yoga est l’union du soleil (ha) et de la lune (tha). C’est, de façon plus générale, l’union des dualités : masculin/féminin, inspire/expire, confort/effort. Nous recherchons dans le Hatha Yoga un équilibre entre toutes les énergies, parfois contraires, qui nous animent avec des postures qui demandent à la fois de la force et du relâchement. Ce type de yoga est accessible à tous, peu importe la condition physique du participant, puisque chaque posture peut être adaptée, modifiée pour accompagner chacun au mieux.</div>
            <div className='yoga-img3'>
              <img 
                src='/img/yoga_img3.png'
                alt='yoga_img3'
                width='85%'
              />
            </div>
          </div>
        </div>
        <div className='me-container' id='prof'>
          <div className='me-grid'>
            <h1 className='me-title'>La prof</h1>
            <div className='me-img'>
              <img 
                src='/img/portrait.png'
                alt='portrait'
              />
            </div>
            <div className='me-txt'>Hello ! Je m’appelle Manon, j’ai 25 ans et je suis passionnée de yoga depuis bientôt 4 ans. J’ai commencé à pratiquer en 2020, entre deux visio à la maison. Au départ un simple challenge personnel d’un mois, j’ai fini par pratiquer tous les jours pendant plus d’un an tellement ça m’a fait du bien. <br/>Lorsque j’ai fini mes études d’ingénieur, j’ai voulu approfondir mes connaissances, d’abord pour mon développement personnel, puis parce que je ressentais l’envie de partager tous les bienfaits du yoga. J’ai donc suivi une formation de professeur de Hatha Yoga de 500h. Je vous propose à présent des séances, basées sur l'écoute de soi et la bienveillance via l'ACL de Marcé.</div>
          </div>
        </div>
        <div className='prog-container' id='calendrier'>
          <div className='prog-grid'>
            <h1 className='prog-title'>Le calendrier</h1>
            <div className='prog-txt'>
              Je vous propose de nous retrouver tous les jeudis, de 18h30 à 19h30 à la salle Atout, rue des Ecoles à Marcé. Pour participer, nul besoin de matériel, tout est prévu. Venez simplement dans des vêtements confortables, permettant de vous mouvoir sans contrainte. <br/><br/><a className='link-to-contact' href='#contact'>Réservez</a> votre place à notre prochaine séance !
            </div>
            <div className='prog-calendar'>
              <Calendar
                localizer={localizer}
                events={classes}
                culture = 'fr'
                messages={{
                  next: '>',
                  previous: '<',
                  today: 'Aujourd\'hui',
                  month: 'Mois',
                  agenda: 'Liste',
                  time: 'Horaire',
                  event: 'Détails',
                  noEventsInRange: 'Rien de prévu pour cette période'
                }}
                views={['month','agenda']}
                formats={{
                  agendaHeaderFormat: ({start: start, end: end}) => 'du ' + moment(start).format('DD/MM/YYYY') + ' au ' + moment(end).format('DD/MM/YYYY'),
                  agendaDateFormat: (date) => moment(date).format('dddd DD MMMM YYYY'),
                  agendaTimeRangeFormat: ({start: start, end: end}) => moment(start).format('H')+'h'+ moment(start).format('mm') + ' - ' + moment(end).format('H')+'h'+ moment(end).format('mm'),
                  monthHeaderFormat: (date) => strUcFirst(moment(date).format('MMMM YYYY'))
                }}
              />
            </div>
          </div>
        </div>
        <div className='price-container' id='prix'>
          <div className='price-grid'>
            <h1 className='price-title'>Les prix</h1>
            <div className='price-txt1'>
              <p><h1>Cotisation à l'ACL</h1><h2>(compris dans le prix de la première séance)</h2><br/>2€/pers.</p>
            </div>
            <div className='price-txt2'>
              <p><h1>Participation demandée</h1><h2>possibilité de payer plusieurs séances d'avance (la réservation reste nécessaire)</h2><br/>4€/pers./séance</p>
            </div>
            <div className='price-txt3'>
              <p><h1>Moyens de paiement</h1><h2>(à donner avant la séance)</h2><br/>liquide ou chèque<br/>à l'ordre de l'ACL</p>
            </div>
          </div>
        </div>
        <div className='contact-container' id='contact'>
          <div className='contact-grid'>
            <h1 className='contact-title'>Me contacter</h1>
            <div className='contact-txt1'>
              <p>Vous avez envie de réserver une place aux prochaines séances ?<br/>Vous avez une question, besoin d'un renseignement ?<br/><br/>Contactez-moi :</p>
            </div>
            <div className='contact-txt2'>
              <p>
                <img src='/img/icon_insta.svg' alt='icon-insta' className='icon-insta'></img>  <a className='link-to-insta' href='https://www.instagram.com/mouneyoga/' target='_blank' rel='noopener noreferrer'>mouneyoga</a><br></br>
                <img src='/img/icon_phone.svg' alt='icon-phone' className='icon-phone'></img>+33 (0)6 86 14 41 23<br></br>
                <img src='/img/icon_mail.svg' alt='icon-mail' className='icon-mail'></img>  manon.desloges@mouneyoga.com
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>    
  )
}

export default App;

//scale(${scrollVal}) pour grossir, 4*scrollVal pour grossir plus vite
//translate(${scrollVal * 50}%, 0%) pour translater
//onMouseOver / onMouseLeave / ...
// CSS : 
/* backdrop-filter: blur(10px); */